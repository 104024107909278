import React from 'react';

const OfflineComponent = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-black px-4">
            <div className="text-center">
                <div className="mb-4 animate-pulse flex justify-center">
                    <svg className="w-20 h-20 sm:w-32 sm:h-32 text-gray-100 animate-bounce" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
                        <path d="M12 6v6l4 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <h1 className="text-2xl sm:text-4xl font-bold text-white mb-4">You are Offline</h1>
                <p className="text-gray-400 mb-8 text-sm sm:text-base">Please check your internet connection and try again.</p>
                <button
                    onClick={() => window.location.reload()}
                    className="px-6 py-2 sm:px-10 sm:py-2 bg-cyan-500 font-semibold text-white rounded-full hover:bg-blue-700 transition duration-300">
                    Retry
                </button>
            </div>
        </div>
    );
};

export default OfflineComponent;
