

// import React from 'react';
// import { AiOutlineHeart, AiFillHeart, AiOutlineMessage } from 'react-icons/ai';
// import { FaPlay as PlayIcon, FaPause as PauseIcon, FaRegSmile } from 'react-icons/fa';
// import { LuDownload } from 'react-icons/lu';
// import EmojiPicker from 'emoji-picker-react';
// import ReactPlayer from 'react-player';
// import { Link } from 'react-router-dom';
// import { BsThreeDots } from 'react-icons/bs';
// import { ImSpinner8 } from 'react-icons/im';
// import { motion } from 'framer-motion';
// import Loader from './../../../components/SpinLoader/Loader';
// import useVideoPost from './PostPreview/useVideoPost';

// const ShareReelsVideo = () => {
//   const {
//     post,
//     loading,
//     error,
//     isPlaying, // Controls whether the video is playing
//     timelineWidth,
//     buttonOpacity,
//     comments,
//     newComment,
//     liked,
//     likeCount,
//     showEmojiPicker,
//     videoLoading,
//     videoContainerRef,
//     emojiPickerRef,
//     inputRef,
//     setTimelineWidth,
//     handleVideoPlay, // Toggle play/pause
//     handleCommentChange,
//     handleCommentSubmit,
//     handleLikeClick,
//     handleCommentLikeClick,
//     handleDownloadClick,
//     handleEmojiClick,
//     handleCommentIconClick,
//     setShowEmojiPicker,
//     setVideoLoading,
//   } = useVideoPost();

//   if (error) return <div>{error}</div>;

//   if (loading) {
//     return (
//       <div className="fixed inset-0 flex items-center justify-center min-h-screen bg-black bg-opacity-50">
//         <ImSpinner8 className="text-neutral-400 text-4xl animate-spin" aria-label="Loading..." />
//       </div>
//     );
//   }

//   // Animation settings
//   const fadeInVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: { opacity: 1, y: 0 },
//   };

//   return (
//     <motion.div initial="hidden" animate="visible" variants={fadeInVariants}>
//       <div className="min-h-screen bg-black bg-opacity-25 text-white font-sans py-8">
//         <main className="lg:max-w-4xl lg:mx-auto">
//           <div className="fixed flex items-center justify-center">
//             <div className="mx-4 flex flex-col sm:flex-row h-full w-full max-w-[900px] max-h-[calc(100vh-80px)] justify-center bg-neutral-800 overflow-hidden">
//               {/* Video Section */}
//               <div
//                 className="relative flex h-[60%] sm:h-auto w-full sm:w-[40%] items-center justify-center bg-black"
//                 ref={videoContainerRef}
//               >
//                 {videoLoading && (
//                   <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//                     <ImSpinner8 className="text-neutral-500 text-4xl animate-spin" />
//                   </div>
//                 )}
//                 <ReactPlayer
//                   url={post.iosUrl}
//                   playing={isPlaying} // Play/pause controlled by isPlaying state
//                   muted={false} // Muted to ensure autoplay compliance with browser rules
//                   controls={false}
//                   width="100%"
//                   height="auto"
//                   onClick={handleVideoPlay} // Toggles play/pause on click
//                   onReady={() => setVideoLoading(false)} // Set loading to false when video is ready
//                   onProgress={({ played }) => setTimelineWidth(played * 100)}
//                   loop
//                   className="h-full w-full cursor-pointer object-cover"
//                 />
//                 {/* Play/Pause Button */}
//                 <button
//                   className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-white text-6xl outline-none z-10"
//                   style={{ opacity: buttonOpacity }}
//                   title={isPlaying ? 'Pause' : 'Play'}
//                   onClick={handleVideoPlay}
//                 >
//                   {isPlaying ? <PauseIcon /> : <PlayIcon />}
//                 </button>

//                 {/* Video Progress Bar */}
//                 <div className="absolute bg-white bottom-0 left-0 h-1 transition-all" style={{ width: `${timelineWidth}%` }}></div>
//               </div>

//               {/* Comments and Actions Section */}
//               <div className="flex h-[40%] sm:h-full w-full flex-col bg-neutral-800 text-white lg:w-[60%]">
//                 {/* Header */}
//                 <div className="flex items-center justify-between border-b border-gray-700 py-2 px-4 sm:p-4 sticky top-0 z-10">
//                   <div className="flex items-center">
//                     <img
//                       src={post?.profilePicture || 'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png'}
//                       alt="user avatar"
//                       className="w-10 h-10 rounded-full"
//                     />
//                     <Link to={`/user/profile/${post.userId}`} className="cursor-pointer ml-3">
//                       <div>
//                         <span className="font-bold">{post?.userName}</span>
//                         <p className="text-gray-400 text-sm">
//                           {post?.firstName || ''} {post?.lastName || ''}
//                         </p>
//                       </div>
//                     </Link>
//                   </div>
//                   <BsThreeDots size={25} className="cursor-pointer text-gray-300" />
//                 </div>

//                 {/* Comments Section */}
//                 <div className="flex-grow overflow-y-auto px-2 py-2 sm:p-2">
//                   {videoLoading ? (
//                     <div className="flex items-center justify-center min-h-[50vh]">
//                       <Loader />
//                     </div>
//                   ) : comments.length === 0 ? (
//                     <div className="flex items-center justify-center min-h-[50vh]">
//                       <p>No comments yet. Be the first to comment!</p>
//                     </div>
//                   ) : (
//                     comments.map((comment, index) => (
//                       <div key={index} className="flex flex-col mb-4">
//                         <div className="flex items-start mb-2">
//                           <img
//                             src={comment.profilePicture || 'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png'}
//                             alt="commenter avatar"
//                             className="w-8 h-8 rounded-full"
//                           />
//                           <div className="ml-3 flex-1">
//                             <Link
//                               to={`/user/profile/${comment.userId}`}
//                               className="text-white font-semibold break-all cursor-pointer"
//                             >
//                               <span className="font-semibold mr-2">{comment.userName}</span>
//                             </Link>
//                             <p className="break-comment break-all text-gray-300 text-xs">{comment.comment}</p>
//                             <div className="flex items-center text-gray-400 text-sm mt-1">
//                               <button className="ml-1 text-xs">• {comment.like || 0} like</button>
//                             </div>
//                           </div>
//                           <button className="ml-3 text-gray-400">
//                             {comment.liked ? (
//                               <AiFillHeart className="text-red-500" onClick={() => handleCommentLikeClick(comment)} />
//                             ) : (
//                               <AiOutlineHeart onClick={() => handleCommentLikeClick(comment)} />
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     ))
//                   )}
//                 </div>

//                 {/* Actions and Input */}
//                 <div className="text-white mt-6">
//                   <div className="border-t border-gray-700 px-5 pt-1 pb-1 sm:pt-4">
//                     <div className="mb-1 flex gap-4 sm:mb-3">
//                       <button className="group">
//                         {liked ? (
//                           <AiFillHeart size={25} onClick={handleLikeClick} className="text-red-500" />
//                         ) : (
//                           <AiOutlineHeart size={25} onClick={handleLikeClick} />
//                         )}
//                       </button>
//                       <button>
//                         <AiOutlineMessage size={25} onClick={handleCommentIconClick} />
//                       </button>
//                       <button>
//                         <LuDownload size={25} onClick={handleDownloadClick} />
//                       </button>
//                     </div>
//                     <span className="font-bold w-full text-sm block text-start mb-1">{likeCount} likes</span>
//                   </div>
//                   <div className="relative flex justify-between border-t border-gray-700  z-20">
//                     <button className="px-5" type="button">
//                       {showEmojiPicker && (
//                         <div ref={emojiPickerRef} className="absolute bottom-12">
//                           <EmojiPicker
//                             lazyLoadEmojis={true}
//                             searchPlaceholder="Search your emoji..."
//                             suggestedEmojisMode="recent"
//                             width={350}
//                             height={450}
//                             onEmojiClick={handleEmojiClick}
//                           />
//                         </div>
//                       )}
//                       <FaRegSmile className="text-white" size={24} onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
//                     </button>
//                     <input
//                       ref={inputRef}
//                       type="text"
//                       className="bg-transparent my-3 outline-none grow text-white"
//                       placeholder="Add a comment..."
//                       value={newComment}
//                       onChange={handleCommentChange}
//                       onFocus={() => setShowEmojiPicker(false)}
//                     />
//                     <button className="pr-4 pl-2 text-sm font-semibold text-blue-500" type="button" onClick={handleCommentSubmit}>
//                       Post
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </main>
//       </div>
//     </motion.div>
//   );
// };

// export default ShareReelsVideo;
















import React from 'react';
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineMessage,
  AiOutlineSmile,
} from "react-icons/ai";
import {
  FaPlay as PlayIcon,
  FaPause as PauseIcon,
  FaSpinner,
  FaRegSmile,
} from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import EmojiPicker from "emoji-picker-react";
import ReactPlayer from "react-player";
import useVideoPost from "./PostPreview/useVideoPost";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import Loader from "./../../../components/SpinLoader/Loader";
import { ImSpinner8 } from "react-icons/im";

const ShareReelsVideo = () => {
  const {
    post,
    loading,
    error,
    isPlaying,
    timelineWidth,
    buttonOpacity,
    comments,
    newComment,
    liked,
    likeCount,
    downloadCount,
    shareCount,
    showEmojiPicker,
    videoLoading,
    videoContainerRef,
    modalRef,
    emojiPickerRef,
    inputRef,
    setTimelineWidth,
    handleVideoPlay,
    handleCommentChange,
    handleCommentSubmit,
    handleLikeClick,
    handleCommentLikeClick,
    handleDownloadClick,
    handleShareClick,
    handleEmojiClick,
    handleCommentIconClick,
    setShowEmojiPicker,
    setVideoLoading,
  } = useVideoPost();

  if (error) return <div>{error}</div>;

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center min-h-screen bg-black bg-opacity-50">
        <ImSpinner8 className="text-neutral-400 text-4xl animate-spin" aria-label="Loading..." />
      </div>
    );
  }



  return (
    <div className="fixed inset-0 flex items-center justify-center lg:ml-40" >
    
      {/* Modal Container */}
      <div className="flex flex-col sm:flex-row h-full w-full max-w-[900px] max-h-[calc(100vh-80px)] justify-center bg-black border border-gray-600  overflow-hidden" >
        {/* Video Section */}
        <div
          className="relative flex h-[60%] sm:h-auto w-full sm:w-[40%] items-center justify-center bg-black border border-gray-600"
          ref={videoContainerRef}
        >
          {videoLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <ImSpinner8 className="text-neutral-500 text-4xl animate-spin" />
            </div>
          )}
          <ReactPlayer
            url={post.iosUrl}
            playing={isPlaying}
            controls={false}
            width="100%"
            height="auto"
            onClick={handleVideoPlay}
            onReady={() => setVideoLoading(false)}
            onProgress={({ played }) => setTimelineWidth(played * 100)}
            loop
            className="h-full w-full cursor-pointer object-cover"
          />
          {/* Play/Pause Button */}
          <button
            className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-white text-6xl outline-none z-10"
            style={{ opacity: isPlaying ? buttonOpacity : 1, pointerEvents: "none" }}
            title={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </button>
          {/* Video Progress Bar */}
          <div
            className="absolute bg-white bottom-0 left-0 h-1 transition-all"
            style={{ width: `${timelineWidth}%` }}
          ></div>
        </div>

        {/* Comments and Actions Section */}
        <div className="flex h-[40%] sm:h-full w-full flex-col bg-black text-white lg:w-[60%]">
          {/* Header */}
          <div className="flex items-center justify-between border-b border-gray-700 py-2 px-4 sm:p-4 sticky top-0 bg-black z-10">
            <div className="flex items-center">
              <img
                src={
                  post?.profilePicture ||
                  "https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png"
                }
                alt="user avatar"
                className="w-10 h-10 rounded-full"
              />
              <Link
                to={`/user/profile/${post.userId}`}
                className="cursor-pointer ml-3"
              >
                <div>
                  <span className="font-bold">{post?.userName}</span>
                  <p className="text-gray-400 text-sm">
                    {post?.firstName || ""} {post?.lastName || ""}
                  </p>
                </div>
              </Link>
            </div>
            <BsThreeDots
              size={25}
              className="cursor-pointer text-gray-300"
             
            />
          </div>

          {/* Comments Section */}
          <div className="flex-grow overflow-y-auto px-2 py-2 sm:p-2">
            {videoLoading ? (
              <div className="flex items-center justify-center min-h-[50vh]">
                <Loader />
              </div>
            ) : comments.length === 0 ? (
              <div className="flex items-center justify-center min-h-[50vh]">
                <p>No comments yet. Be the first to comment!</p>
              </div>
            ) : (
              comments.map((comment, index) => (
                <div key={index} className="flex flex-col mb-4">
                  <div className="flex items-start mb-2">
                    <img
                      src={
                        comment.profilePicture ||
                        "https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png"
                      }
                      alt="commenter avatar"
                      className="w-8 h-8 rounded-full comment-avatar"
                    />
                    <div className="ml-3 flex-1">
                      <Link
                        to={`/user/profile/${comment.userId}`}
                        className="text-white font-semibold break-all cursor-pointer"
                      >
                        <span className="font-semibold mr-2">
                          {comment.userName}
                        </span>
                      </Link>
                      <p className="break-comment break-all text-gray-300 text-xs">
                        {comment.comment}
                      </p>
                      <div className="flex items-center text-gray-400 text-sm mt-1">
                        <button className="ml-1 text-xs">
                          • {comment.like || 0} like
                        </button>
                      </div>
                    </div>
                    <button className="ml-3 text-gray-400">
                      {comment.liked ? (
                        <AiFillHeart
                          className="text-red-500"
                          onClick={() => handleCommentLikeClick(comment)}
                        />
                      ) : (
                        <AiOutlineHeart
                          onClick={() => handleCommentLikeClick(comment)}
                        />
                      )}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Actions and Input */}
          <div className="text-white">
            <div className="border-t border-gray-700 px-5 pt-1 pb-1 sm:pt-4">
              <div className="mb-1 flex gap-4 sm:mb-3">
                <button className="group">
                  {liked ? (
                    <AiFillHeart
                      size={25}
                      onClick={handleLikeClick}
                      className="text-red-500"
                    />
                  ) : (
                    <AiOutlineHeart size={25} onClick={handleLikeClick} />
                  )}
                </button>
                <button>
                  <AiOutlineMessage
                    size={25}
                    onClick={handleCommentIconClick}
                  />
                </button>
                <button>
                  <LuDownload size={25} onClick={handleDownloadClick} />
                </button>
              </div>
              <span className="font-bold w-full text-sm block text-start mb-1">
                {likeCount} likes
              </span>
            </div>
            <div className="relative flex justify-between border-t border-gray-700 pb-1">
              <button className="px-5" type="button">
                {showEmojiPicker && (
                  <div ref={emojiPickerRef} className="absolute bottom-12">
                    <EmojiPicker
                      lazyLoadEmojis={true}
                      searchPlaceholder="Search your emoji..."
                      suggestedEmojisMode="recent"
                      width={350}
                      height={450}
                      onEmojiClick={handleEmojiClick}
                    />
                  </div>
                )}
                <FaRegSmile
                  className="text-white"
                  size={24}
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
              </button>
              <input
                ref={inputRef}
                type="text"
                className="bg-transparent my-3 outline-none grow text-white"
                placeholder="Add a comment..."
                value={newComment}
                onChange={handleCommentChange}
                onFocus={() => setShowEmojiPicker(false)}
              />
              <button
                className="pr-4 pl-2 text-sm font-semibold text-blue-500"
                type="button"
                onClick={handleCommentSubmit}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default ShareReelsVideo;
