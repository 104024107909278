// import React from 'react'
// import logo from '../../assets/Images/Loading.svg'

// const Loader = () => {
//   return (
//     <>
//      <div className="flex h-[100vh] w-full items-center justify-center">
//         <div className="animate-spin rounded-full  p-2">
//           <picture>
//             <img
//             className='h-20 w-20'
//               src={logo}
//               alt="loading"
//             />
//           </picture>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Loader









// import React from 'react';

// const Loader = () => {
//   return (
//     <div className="flex h-[100vh] w-full items-center justify-center">
//       <div className="p-2">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 100 100"
//           preserveAspectRatio="xMidYMid"
//           className="lds-ring"
//           style={{ background: 'none' }}
//           width="200px"
//           height="200px"
//         >
//           <circle
//             cx="50"
//             cy="50"
//             r="32"
//             strokeWidth="8"
//             stroke="#46c7f1"
//             strokeDasharray="50.26548245743669 50.26548245743669"
//             fill="none"
//             strokeLinecap="round"
//           >
//             <animateTransform
//               attributeName="transform"
//               type="rotate"
//               repeatCount="indefinite"
//               dur="1s"
//               values="0 50 50;360 50 50"
//               keyTimes="0;1"
//             ></animateTransform>
//           </circle>
//         </svg>
//       </div>

      
//     </div>
//   );
// }

// export default Loader;






// import React from 'react'

// const Loader = () => {
//   return (
//     <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <svg
//         version="1.1"
//         id="L9"
//         xmlns="http://www.w3.org/2000/svg"
//         x="0px"
//         y="0px"
//         viewBox="0 0 100 100"
//         enableBackground="new 0 0 0 0"
//         className="lds-circle"
//         style={{ background: "none" }}
//         width="200px"
//         height="200px"
//       >
//         <circle fill="none" stroke="#46c7f1" strokeWidth="4" cx="50" cy="50" r="44" style={{ opacity: 0.5 }} />
//         <circle fill="#46c7f1" stroke="#46c7f1" strokeWidth="3" cx="8" cy="54" r="6">
//           <animateTransform
//             attributeName="transform"
//             dur="2s"
//             type="rotate"
//             from="0 50 48"
//             to="360 50 52"
//             repeatCount="indefinite"
//           />
//         </circle>
//       </svg>
//     </div>
//   )
// }

// export default Loader;




// import React from 'react'

// const Loader = () => {
//   return (
//     <div>
//          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 100 100"
//             preserveAspectRatio="xMidYMid"
//             className="lds-ring"
//             style={{ background: "none" }}
//             width="200px"
//             height="200px"
//           >
//             <circle
//               cx="50"
//               cy="50"
//               r="32"
//               strokeWidth="8"
//               stroke="#46c7f1"
//               strokeDasharray="50.26548245743669 50.26548245743669"
//               fill="none"
//               strokeLinecap="round"
//             >
//               <animateTransform
//                 attributeName="transform"
//                 type="rotate"
//                 repeatCount="indefinite"
//                 dur="1s"
//                 values="0 50 50;360 50 50"
//                 keyTimes="0;1"
//               ></animateTransform>
//             </circle>
//           </svg>
//         </div>
//     </div>
//   )
// }

// export default Loader















// import React from 'react';

// function LoadingUserPosts() {
//   const mapArray = [1, 2, 3];
//   const filler = [1, 2, 3, 4, 5];

//   return (
//     <>
//       <div className="grid max-w-[935px] animate-pulse grid-cols-3 gap-1 pb-10 sm:gap-4">
//         {mapArray.map((index) => (
//           <div
//             key={`userPost${index}`}
//             className="h-[175px] max-w-[300px] bg-[#ebebeb] dark:bg-[#313131] sm:h-[300px]"
//           />
//         ))}
//       </div>

//       <div>
//         {filler.map((index) => (
//           <div className="flex animate-pulse py-1" key={`fillerKey${index}`}>
//             <div className="h-8 w-8 rounded-full bg-[#ebebeb] dark:bg-[#313131]" />
//             <div className="flex flex-col justify-between pl-4">
//               <div className="h-3 w-12 rounded-sm bg-[#ebebeb] dark:bg-[#313131]" />
//               <div className="h-3 w-24 rounded-sm bg-[#ebebeb] dark:bg-[#313131]" />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Loading post */}
//       <div className="mt-6 w-full max-w-[470px] rounded-lg border border-stone-300 bg-white dark:border-stone-700 dark:bg-[#1c1c1c]">
//         <div className="flex h-14 items-center">
//           <div className="ml-5 h-8 w-8 animate-pulse rounded-full bg-[#ebebeb] dark:bg-[#313131]" />
//           <div className="ml-5 h-5 w-[200px] animate-pulse rounded-sm bg-[#ebebeb] dark:bg-[#313131]" />
//         </div>
//         <div className="h-[300px] animate-pulse bg-[#ebebeb] dark:bg-[#313131]" />
//         <div className="flex h-14 items-center">
//           <div className="ml-5 h-5 w-[200px] animate-pulse rounded-sm bg-[#ebebeb] dark:bg-[#313131]" />
//         </div>
//       </div>

    //   {/* Chat room */}
    //   <div className="px-2 py-2 md:px-5">
    //     {filler.map((index) => (
    //       <div
    //         className="flex animate-pulse items-center py-1"
    //         key={`fillerChat${index}`}
    //       >
    //         <div className="h-6 w-6 rounded-full bg-[#ebebeb] dark:bg-[#313131] md:h-14 md:w-14" />
    //         <div className="rounded-xs ml-2 h-3 w-[60%] bg-[#efefef] dark:bg-[#313131] md:h-5 md:w-[40%] md:rounded-sm" />
    //       </div>
    //     ))}
    //   </div>

//       {/* Heart post */}
//       <div className="absolute top-6 right-[-80px] h-[280px] w-[270px] cursor-default text-[#262626] dark:text-[#f1f5f9] sm:right-[-12px] sm:w-[440px]">
//         <div className="ml-auto mr-[84px] flex h-4 w-4 items-center justify-center overflow-hidden sm:mr-4">
//           <div className="mt-5 h-4 w-4 rotate-45 bg-white dark:bg-[#131313]" />
//         </div>
//         <div className="flex h-full items-center justify-center rounded-md bg-white py-4 shadow-[-2px_-2px_10px_2px_rgba(0,0,0,0.1)] dark:bg-[#131313] dark:shadow-[-2px_-2px_5px_2px_rgba(0,0,0,0.7)]">
//           <div className="h-6 w-6">
//             icon
//             {/* <SpinnerSVG /> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default LoadingUserPosts;



import React from 'react';

function Loader() {
  const filler = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="px-4 py-4 w-full">
      {filler.map((index) => (
        <div
          className="flex animate-pulse items-center py-3"
          key={`fillerChat${index}`}
        >
          <div className="h-12 w-12 rounded-full bg-gray-300 dark:bg-gray-700" />
          <div className="flex flex-col justify-between w-full ml-4">
            <div className="h-3 w-40 rounded bg-gray-300 dark:bg-gray-700 mb-2" />
            <div className="h-3 w-64 rounded bg-gray-300 dark:bg-gray-700" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Loader;
