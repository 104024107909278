import React, { useState } from "react";
import { Link } from "react-router-dom";
import ChangeProfilePhoto from "./ChangeProfilePhoto"; // Import the ChangeProfilePhoto component

const EditProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = {
    firstName: "Dilip",
    lastName: "",
    username: "dleepjiq",
    email: "",
    gender: "",
    dob: "30-Nov-0002",
    aboutMe: "",
    avatar: "https://example.com/avatar.jpg",
  };

  return (
    <>
      <form className="p-10 basis-full md:basis-9/12 ">
        <div className="flex w-full items-center ">
          <div className="flex text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 justify-end">
            <img
              loading="lazy"
              className="w-16 h-16 rounded-full p-2"
              src={user.avatar}
              alt="User Avatar"
            />
          </div>
          <div className="block w-full basis-10/12">
            <h2>{user.firstName}</h2>
            <h2
              className="text-sky-500 dark:hover:text-white cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              Change profile photo
            </h2>
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="firstName"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>First Name</strong>
          </label>
          <div className="basis-10/12">
            <input
              type="text"
              name="firstName"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={user.firstName}
            />
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="lastName"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>Last Name</strong>
          </label>
          <div className="basis-10/12">
            <input
              type="text"
              name="lastName"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={user.lastName}
            />
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="username"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>Username</strong>
          </label>
          <div className="basis-10/12">
            <input
              type="text"
              name="username"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={user.username}
            />
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="gender"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>Gender</strong>
          </label>
          <div className="basis-10/12">
            <input
              type="text"
              name="gender"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={user.gender}
            />
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="dob"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>Date of Birth</strong>
          </label>
          <div className="basis-10/12">
            <input
              type="text"
              name="dob"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={user.dob}
            />
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="aboutMe"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>About Me</strong>
          </label>
          <div className="basis-10/12">
            <textarea
              name="aboutMe"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
              value={user.aboutMe}
            />
          </div>
        </div>

        <div className="mb-5 flex w-full items-center">
          <label
            htmlFor="email"
            className="text-right block text-sm font-medium text-gray-900 dark:text-white basis-2/12 mr-5 py-3"
          >
            <strong>Email</strong>
          </label>
          <div className="basis-10/12">
            <input
              type="text"
              name="email"
              className="bg-gray-50 dark:bg-ig-dark-secondary-background border border-gray-300 dark:border-ig-dark-elevated-separator text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={user.email}
            />
          </div>
        </div>

        <div className="mb-6 flex w-full items-center">
          <div htmlFor="name" className="basis-2/12 mr-5 py-3"></div>
          <div className="basis-10/12 flex">
            <button
              type="submit"
              className="mb-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-auto px-5 py-2.5 text-center"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-5">
            <ChangeProfilePhoto />
            <button
              className="mt-3 text-red-500"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfile;
