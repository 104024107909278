import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center bg-black text-white p-4">
      <div className="text-center max-w-lg">
        <div className="mx-auto mb-5">
          <svg
            width="200"
            height="200"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto animate-pulse"
          >
            <path
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm1 4v4h4v2h-6V6h2zm-4 4v2H7v-2h2zm0 2v2H7v-2h2zm0 2v2H7v-2h2z"
              fill="#46c7f1"
            />
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="#46c7f1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="opacity-25 animate-ping"
            />
          </svg>
        </div>
        <b className="text-xl mb-5 block">Sorry, this page isn't available.</b>
        <p className="mb-4">
          The link you followed may be broken, or the page may have been removed.
        </p>
        <Link to="/profile" className="text-[#46c7f1] font-semibold">
          Go back to Bloopers.
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
