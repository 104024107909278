import React from 'react';
import Logo from '../../assets/Images/logo.png';

const BloopersLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black relative p-5">
      <div className="flex flex-col items-center">
        <img
          src={Logo}
          alt="Logo"
          className="w-20 h-20  max-w-[80px] animate-pulse"
        />
      </div>
      <div className="absolute bottom-5 flex flex-col items-center">
        <p className="text-sm text-gray-300">from</p>
        <span className="text-md md:text-md font-mono text-cyan-400 font-semibold">Lalit Mittal Technologies</span>
      </div>
    </div>
  );
};

export default BloopersLoader;
