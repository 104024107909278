// store/store.js

import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './components/Redux/slices/searchSlice';

const store = configureStore({
  reducer: {
    search: searchReducer,
  },
});

export default store;
