// import React from "react";
// import { VscClose as CloseIcon } from "react-icons/vsc";
// import { FiLogOut, FiXCircle } from "react-icons/fi";
// import toast, { Toaster } from 'react-hot-toast';
// const LogoutModal = ({ onRequestClose }) => {
//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user_id");
//     toast.success("Logged out successfully!");
//     window.location.href = "/";
//   };

//   return (
//     <>
//      <Toaster />
//     <div className="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center transition-opacity duration-300 ease-in-out">
//       <div  onClick={onRequestClose} className="absolute h-full w-full bg-black/80 backdrop-blur-xs -z-10"></div>
//       <button
//         onClick={onRequestClose}
//         className="absolute top-5 right-5 text-white text-3xl md:text-5xl"
//       >
//         <CloseIcon color="#fff"  className="animate-bounce"/>
//       </button>
     
//       {/* <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden max-w-md w-full  md:mx-0"> */}
//       <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
//       <div className="w-full px-4 h-12 bg-gray-300 rounded-t-xl text-black flex items-center justify-center border-b border-neutral-500">
//           <span className="font-semibold">Log Out</span>
//           <CloseIcon
//             size={25}
//             className="absolute right-4 cursor-pointer hover:text-red-700"
//             onClick={onRequestClose}
//           />
//         </div>
//         <div className="p-5 text-center">
//           <div className="flex justify-center mb-4">
//             <FiLogOut className="w-16 h-16 text-red-600" />
//           </div>
//           <p className="mb-6 text-gray-600 dark:text-gray-300">
//               Are you sure you want to log out from your account?
//             </p>
//           <div className="flex justify-center gap-4">
//             <button
//               onClick={handleLogout}
//               className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
//             >
//               <FiLogOut className="text-xl" />
//               Log Out
//             </button>
//             <button
//              onClick={onRequestClose}
//               className="flex items-center gap-2 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition"
//             >
//               <FiXCircle className="text-xl" />
//               Cancel
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// };

// export default LogoutModal;






import React from "react";
import { VscClose as CloseIcon } from "react-icons/vsc";
import { FiLogOut, FiXCircle } from "react-icons/fi";
import toast, { Toaster } from 'react-hot-toast';

const LogoutModal = ({ onRequestClose }) => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    toast.success("Logged out successfully!");
    window.location.href = "/";
  };

  return (
    <>
      <Toaster position="top-center"/>
      <div className="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center transition-opacity duration-300 ease-in-out">
        <div
          onClick={onRequestClose}
          className="absolute h-full w-full bg-black/80 backdrop-blur-xs -z-10"
        ></div>
        <button
          onClick={onRequestClose}
          className="absolute top-5 right-5 text-white text-3xl md:text-5xl"
        >
          <CloseIcon className="animate-bounce text-white cursor-pointer hover:text-red-700" />
        </button>

        <div className="bg-neutral-900 rounded-lg shadow-lg max-w-md w-full">
          <div className="w-full px-4 h-12 bg-neutral-800 rounded-t-xl text-white flex items-center justify-center border-b border-neutral-500">
            <span>Log Out</span>
          </div>
          <div className="p-5 text-center">
            <div className="flex justify-center mb-4">
              <FiLogOut className="w-16 h-16 text-red-500" />
            </div>
            <p className="mb-6 text-gray-300">
              Are you sure you want to log out from your account?
            </p>
            
            <div className="flex justify-center gap-4">
              <button
                onClick={handleLogout}
                className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
              >
                <FiLogOut className="text-xl" />
                Log Out
              </button>
              <button
                onClick={onRequestClose}
                className="flex items-center gap-2 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition"
              >
                <FiXCircle className="text-xl" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoutModal;











// import React from "react";
// import { motion } from "framer-motion";
// import { FiLogOut, FiXCircle } from "react-icons/fi";

// // LogoutModal Component Start
// const LogoutModal = ({ handleLogout, setModelOpen  }) => {
//   // If the modal is not to be shown, return null
//   if (!setModelOpen) return null;

//   // Handle closing the modal
//   const handleClose = () => {
//     setModelOpen(false);
//   };

//   // const handleLogout = () => {
//   //   localStorage.removeItem("token");
//   //   window.location.href = "/login"; // Redirect to login page
//   // };

//   // Animation variants for the modal
//   const modalVariants = {
//     hidden: { opacity: 0, scale: 0.8 },
//     visible: { opacity: 1, scale: 1 },
//   };

//   return (
//     // Modal backdrop Start
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60"          onClick={() => setModelOpen(false)}>
//       {/* Modal container Start */}
//       <motion.div
//         className="relative bg-neutral-900 rounded-lg shadow-lg w-[90%] max-w-[460px] text-center p-6"
//         onClick={(e) => e.stopPropagation()}
//         initial="hidden"
//         animate="visible"
//         exit="hidden"
//         variants={modalVariants}
//         transition={{ duration: 0.3 }}
//       >
//         {/* Close button */}
        // <button
        //           onClick={() => setModelOpen(false)}
        //   className="absolute top-4 right-4 text-white text-2xl"
        // >
        //   <FiXCircle />
        // </button>

//         {/* Modal content Start */}
//         <div className="flex flex-col items-center">
//           <FiLogOut className="w-16 h-16 text-red-600 mb-4" />
//           <h2 className="text-2xl text-white mb-4">Log Out</h2>
//           <p className="text-gray-400 mb-6">Are you sure you want to log out?</p>
//           <div className="flex gap-4">
  //           <button
  // onClick={handleLogout}
  //             className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
  //           >
  //             <FiLogOut className="text-xl" />
  //             Log Out
  //           </button>
  //           <button
  //                   onClick={() => setModelOpen(false)}
  //             className="flex items-center gap-2 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition"
  //           >
  //             <FiXCircle className="text-xl" />
  //             Cancel
  //           </button>
//           </div>
//         </div>
//         {/* Modal content End */}
//       </motion.div>
//       {/* Modal container End */}
//     </div>
//     // Modal backdrop End
//   );
// };
// // LogoutModal Component End

// export default LogoutModal;
