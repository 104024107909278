import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaHome, FaSearch, FaCompass, FaHeart, FaUser } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import logo from '../../assets/Images/logo.png';
import Icon from '../../assets/Images/bloopers.png';
import LogoutModal from './LogoutModal';

const LargeNav = () => {
  const { pathname } = useLocation();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const navLinks = [
    { to: '/reels', icon: <FaHome size={20} />, label: 'Home' },
    { to: '/search', icon: <FaSearch size={20} />, label: 'Search' },
    { to: '/explore', icon: <FaCompass size={20} />, label: 'Explore' },
    { to: '/notification', icon: <FaHeart size={20} />, label: 'Notifications' },
    { to: '/profile', icon: <FaUser size={20} />, label: 'Profile' },
  ];

  return (
    <aside className="fixed left-0 top-0 z-40 flex h-screen w-16 md:w-20 lg:w-64 flex-col shadow-2xl bg-black text-white border-r-2 border-neutral-600 transition-transform duration-300 ease-linear lg:static lg:translate-x-0">
      {/* Logo section */}
      <div className="flex items-center justify-center lg:justify-start px-2 py-4">
        <NavLink to="#" className="flex justify-center">
          <img src={Icon} alt="Icon" className="md:hidden w-10 h-auto" />
          <img src={logo} alt="Logo" className="hidden md:block md:w-16 lg:w-28 h-auto" />
        </NavLink>
      </div>

      {/* Sidebar Navigation Links */}
      <nav className="flex flex-col flex-grow space-y-3 px-2 md:px-4 py-4 overflow-y-auto no-scrollbar">
        {navLinks.map(({ to, icon, label }) => (
          <NavLink
            key={to}
            to={to}
            className={`group flex items-center justify-center lg:justify-start px-4 py-3 rounded-lg transition-colors duration-300 ${
              pathname === to
                ? 'bg-[#56b5f4] text-white dark:bg-[#1f2560] font-semibold'
                : 'text-white hover:bg-neutral-700 font-normal'
            }`}
          >
            <span className="mr-0 lg:mr-4 group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300">
              {icon}
            </span>
            <span className="hidden lg:block">{label}</span>
          </NavLink>
        ))}
      </nav>

      {/* Logout Button Positioned at the Bottom */}
      <div className="px-4 py-4 mt-auto">
        <button
          className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 text-red-500 hover:text-red-600 w-full transition-colors duration-300"
          onClick={() => setLogoutModalOpen(true)}
        >
          <FiLogOut size={25} />
          <span className="hidden lg:block font-mono font-semibold">Logout</span>
        </button>
      </div>

      {logoutModalOpen && <LogoutModal onRequestClose={() => setLogoutModalOpen(false)} />}
    </aside>
  );
};

export default LargeNav;
