import { useState, useEffect } from 'react';

const useNetworkStatus = () => {
    const [networkStatus, setNetworkStatus] = useState({
        isOnline: navigator.onLine,
        connectionType: navigator.connection?.type || 'unknown',
        effectiveType: navigator.connection?.effectiveType || 'unknown'
    });

    const updateNetworkStatus = () => {
        setNetworkStatus({
            isOnline: navigator.onLine,
            connectionType: navigator.connection?.type || 'unknown',
            effectiveType: navigator.connection?.effectiveType || 'unknown'
        });
        console.log(`Network status: ${navigator.onLine ? 'Online' : 'Offline'}, Connection type: ${navigator.connection?.type || 'unknown'}, Effective type: ${navigator.connection?.effectiveType || 'unknown'}`);
    };

    useEffect(() => {
        const handleOnline = () => {
            console.log("Network status: Online");
            updateNetworkStatus();
        };

        const handleOffline = () => {
            console.log("Network status: Offline");
            updateNetworkStatus();
        };

        const handleConnectionChange = () => {
            console.log("Network connection type changed");
            updateNetworkStatus();
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        navigator.connection?.addEventListener('change', handleConnectionChange);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            navigator.connection?.removeEventListener('change', handleConnectionChange);
        };
    }, []);

    return networkStatus;
};

export default useNetworkStatus;









// import { useState, useEffect } from 'react';

// const useNetworkStatus = () => {
//     const [isOnline, setIsOnline] = useState(navigator.onLine);

//     useEffect(() => {
//         const handleOnline = () => {
//             console.log("Network status: Online");
//             setIsOnline(true);
//         };
//         const handleOffline = () => {
//             console.log("Network status: Offline");
//             setIsOnline(false);
//         };

//         window.addEventListener('online', handleOnline);
//         window.addEventListener('offline', handleOffline);

//         return () => {
//             window.removeEventListener('online', handleOnline);
//             window.removeEventListener('offline', handleOffline);
//         };
//     }, []);

//     return isOnline;
// };

// export default useNetworkStatus;
