// import { useState, useEffect, useCallback, useRef } from "react";
// import axios from "axios";
// import { encode, decode } from "utf8";

// const useVideoPost = (posts, initialIndex) => {
//   const [currentIndex, setCurrentIndex] = useState(initialIndex);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [timelineWidth, setTimelineWidth] = useState(0);
//   const [buttonOpacity, setButtonOpacity] = useState(0);
//   const [comments, setComments] = useState([]);
//   const [newComment, setNewComment] = useState("");
//   const [liked, setLiked] = useState(false);
//   const [likeCount, setLikeCount] = useState(0);
//   const [downloadCount, setDownloadCount] = useState(0);
//   const [shareCount, setShareCount] = useState(0);
//   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//   const [videoLoading, setVideoLoading] = useState(true);

//   const videoContainerRef = useRef(null);
//   const modalRef = useRef(null);
//   const emojiPickerRef = useRef(null);
//   const inputRef = useRef(null);

//   const post = posts[currentIndex];

//   useEffect(() => {
//     if (post) {
//       setLikeCount(post.likes || 0);
//       setDownloadCount(post.downloads || 0);
//       setShareCount(post.shares || 0);
//       fetchComments();
//     }
//   }, [currentIndex, post]);

//   const callBack = useCallback((entries) => {
//     const [entry] = entries;
//     setIsPlaying(entry.isIntersecting);
//   }, []);

//   const options = {
//     root: null,
//     rootMargin: "0px",
//     threshold: 0.5,
//   };

//   const showButton = () => {
//     setButtonOpacity(0.5);
//     setTimeout(() => {
//       setButtonOpacity(0);
//     }, 0);
//   };

//   const handleVideoPlay = () => {
//     showButton();
//     setIsPlaying((prevIsPlaying) => !prevIsPlaying);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(callBack, options);
//     if (videoContainerRef.current) observer.observe(videoContainerRef.current);
//     return () => {
//       if (videoContainerRef.current)
//         observer.unobserve(videoContainerRef.current);
//     };
//   }, [callBack]);

//   const handleClickOutside = useCallback((event) => {
//     if (
//       modalRef.current &&
//       !modalRef.current.contains(event.target) &&
//       (!emojiPickerRef.current ||
//         !emojiPickerRef.current.contains(event.target))
//     ) {
//       setShowEmojiPicker(false);
//     }
//   }, []);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [handleClickOutside]);

//   const handleNextPost = (event) => {
//     event.stopPropagation();
//     if (currentIndex < posts.length - 1) {
//       setCurrentIndex((prevIndex) => prevIndex + 1);
//       setVideoLoading(true);
//     }
//   };

//   const handlePreviousPost = (event) => {
//     event.stopPropagation();
//     if (currentIndex > 0) {
//       setCurrentIndex((prevIndex) => prevIndex - 1);
//       setVideoLoading(true);
//     }
//   };

//   const fetchComments = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         {
//           params: { videoId: post.id },
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
      
//       if (response?.status === 200 && response.data?.data?.Items) {
//         const decodedComments = response.data.data.Items.map((comment) => ({
//           ...comment,
//           comment: decode(comment.comment),
//         }));
//         setComments(decodedComments);
//       } else {
//         console.error("Failed to fetch comments:", response.status);
//       }
//     } catch (error) {
//       console.error("Error fetching video comments:", error);
//     }
//   };

  // const handleCommentChange = (e) => {
  //   setNewComment(e.target.value);
  // };

//   const handleCommentSubmit = async (e) => {
//     e.preventDefault();
//     if (!newComment.trim()) return;

//     try {
//       const token = localStorage.getItem("token");
//       const encodedComment = encode(newComment);
//       const formData = {
//         videoUserId: post?.userId,
//         videoId: post?.id,
//         comment: encodedComment,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response?.status === 201) {
//         setNewComment("");
//         fetchComments();
//       } else {
//         console.error("Failed to post comment:", response.status);
//       }
//     } catch (error) {
//       console.error("Error posting comment:", error);
//     }
//   };

//   const handleLikeClick = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const bodyData = {
//         videoUserId: post.userId,
//         videoId: post.id,
//         like: liked ? 0 : 1,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         bodyData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 201) {
//         setLiked(!liked);
//         setLikeCount((prevCount) => (liked ? prevCount - 1 : prevCount + 1));
//       } else {
//         console.error("Failed to like/unlike the video:", response.status);
//       }
//     } catch (error) {
//       console.error("Error toggling like:", error);
//     }
//   };

//   const handleCommentLikeClick = async (comment) => {
//     try {
//       const token = localStorage.getItem("token");
//       const bodyData = {
//         videoId: comment.videoId,
//         commentId: comment.id,
//         like: comment.liked ? 2 : 1,
//       };

//       const response = await axios.patch(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         bodyData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 200) {
//         const updatedComments = comments.map((c) =>
//           c.id === comment.id
//             ? {
//                 ...c,
//                 liked: !comment.liked,
//                 like: comment.liked ? c.like - 1 : c.like + 1,
//               }
//             : c
//         );
//         setComments(updatedComments);
//       } else {
//         console.error("Failed to like/unlike the comment:", response.status);
//       }
//     } catch (error) {
//       console.error("Error toggling like on comment:", error);
//     }
//   };

//   const handleDownloadClick = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const bodyData = {
//         videoUserId: post.userId,
//         videoId: post.id,
//         downloads: 1,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         bodyData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 201) {
//         setDownloadCount((prevCount) => prevCount + 1);
//         const link = document.createElement("a");
//         link.href = post.iosUrl;
//         link.setAttribute("download", "video.mp4");
//         document.body.appendChild(link);
//         link.click();
//         link.remove();
//       } else {
//         console.error("Failed to update download count:", response.status);
//       }
//     } catch (error) {
//       console.error("Error downloading video:", error);
//     }
//   };

//   const handleShareClick = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const bodyData = {
//         videoUserId: post.userId,
//         videoId: post.id,
//         share: 1,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         bodyData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 201) {
//         setShareCount((prevCount) => prevCount + 1);
//       } else {
//         console.error("Failed to update share count:", response.status);
//       }
//     } catch (error) {
//       console.error("Error sharing video:", error);
//     }
//   };

//   const handleEmojiClick = (emojiData) => {
//     setNewComment((prevComment) => prevComment + emojiData.emoji);
//     setShowEmojiPicker(false);
//   };

//   const handleCommentIconClick = () => {
//     inputRef.current.focus();
//   };

//   return {
//     currentIndex,
//     setCurrentIndex,
//     isPlaying,
//     timelineWidth,
//     buttonOpacity,
//     comments,
//     newComment,
//     liked,
//     likeCount,
//     downloadCount,
//     shareCount,
//     showEmojiPicker,
//     videoLoading,
//     videoContainerRef,
//     modalRef,
//     emojiPickerRef,
//     inputRef,
//     setTimelineWidth,
//     handleVideoPlay,
//     handleNextPost,
//     handlePreviousPost,
//     handleCommentChange,
//     handleCommentSubmit,
//     handleLikeClick,
//     handleCommentLikeClick,
//     handleDownloadClick,
//     handleShareClick,
//     handleEmojiClick,
//     handleCommentIconClick,
//     setShowEmojiPicker,
//     setVideoLoading,
//   };
// };

// export default useVideoPost;



// import { useState, useEffect, useCallback, useRef } from "react";
// import axios from "axios";
// import { encode, decode } from "utf8";

// const useVideoPost = (posts, initialIndex) => {
//   const [currentIndex, setCurrentIndex] = useState(initialIndex);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [timelineWidth, setTimelineWidth] = useState(0);
//   const [buttonOpacity, setButtonOpacity] = useState(0);
//   const [comments, setComments] = useState([]);
//   const [newComment, setNewComment] = useState("");
//   const [liked, setLiked] = useState(false);
//   const [likeCount, setLikeCount] = useState(0);
//   const [downloadCount, setDownloadCount] = useState(0);
//   const [shareCount, setShareCount] = useState(0);
//   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//   const [videoLoading, setVideoLoading] = useState(true);

//   const videoContainerRef = useRef(null);
//   const modalRef = useRef(null);
//   const emojiPickerRef = useRef(null);
//   const inputRef = useRef(null);

//   const post = posts[currentIndex];

//   useEffect(() => {
//     if (post) {
//       initializePostData();
//       fetchComments();
//     }
//   }, [currentIndex, post]);

//   const initializePostData = () => {
//     setLikeCount(post.likes || 0);
//     setDownloadCount(post.downloads || 0);
//     setShareCount(post.shares || 0);
//   };

//   const callBack = useCallback((entries) => {
//     const [entry] = entries;
//     setIsPlaying(entry.isIntersecting);
//   }, []);

//   const options = {
//     root: null,
//     rootMargin: "0px",
//     threshold: 0.5,
//   };

//   const showButton = () => {
//     setButtonOpacity(0.5);
//     setTimeout(() => {
//       setButtonOpacity(0);
//     }, 0);
//   };

//   const handleVideoPlay = () => {
//     showButton();
//     setIsPlaying((prevIsPlaying) => !prevIsPlaying);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(callBack, options);
//     if (videoContainerRef.current) observer.observe(videoContainerRef.current);
//     return () => {
//       if (videoContainerRef.current)
//         observer.unobserve(videoContainerRef.current);
//     };
//   }, [callBack]);

//   const handleClickOutside = useCallback((event) => {
//     if (
//       modalRef.current &&
//       !modalRef.current.contains(event.target) &&
//       (!emojiPickerRef.current ||
//         !emojiPickerRef.current.contains(event.target))
//     ) {
//       setShowEmojiPicker(false);
//     }
//   }, []);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [handleClickOutside]);

//   const handleNextPost = (event) => {
//     event.stopPropagation();
//     if (currentIndex < posts.length - 1) {
//       setCurrentIndex((prevIndex) => prevIndex + 1);
//       setVideoLoading(true);
//     }
//   };

//   const handlePreviousPost = (event) => {
//     event.stopPropagation();
//     if (currentIndex > 0) {
//       setCurrentIndex((prevIndex) => prevIndex - 1);
//       setVideoLoading(true);
//     }
//   };

//   const fetchComments = async () => {
//     try {
//       const response = await apiGet("/comment", {
//         params: { videoId: post.id },
//       });

//       if (response?.status === 200 && response.data?.data?.Items) {
//         const decodedComments = response.data.data.Items.map((comment) => ({
//           ...comment,
//           comment: decode(comment.comment),
//         }));
//         setComments(decodedComments);
//       } else {
//         console.error("Failed to fetch comments:", response.status);
//       }
//     } catch (error) {
//       console.error("Error fetching video comments:", error);
//     }
//   };

//   const handleCommentChange = (e) => {
//     setNewComment(e.target.value);
//   };

//   const handleCommentSubmit = async (e) => {
//     e.preventDefault();
//     if (!newComment.trim()) return;

//     try {
//       const encodedComment = encode(newComment);
//       const formData = {
//         videoUserId: post?.userId,
//         videoId: post?.id,
//         comment: encodedComment,
//       };

//       const response = await apiPost("/comment", formData);

//       if (response?.status === 201) {
//         setNewComment("");
//         fetchComments();
//       } else {
//         console.error("Failed to post comment:", response.status);
//       }
//     } catch (error) {
//       console.error("Error posting comment:", error);
//     }
//   };

//   const handleLikeClick = async () => {
//     try {
//       const bodyData = {
//         videoUserId: post.userId,
//         videoId: post.id,
//         like: liked ? 0 : 1,
//       };

//       const response = await apiPost("/updateVideo", bodyData);

//       if (response.status === 201) {
//         setLiked(!liked);
//         setLikeCount((prevCount) => (liked ? prevCount - 1 : prevCount + 1));
//       } else {
//         console.error("Failed to like/unlike the video:", response.status);
//       }
//     } catch (error) {
//       console.error("Error toggling like:", error);
//     }
//   };

//   const handleCommentLikeClick = async (comment) => {
//     try {
//       const bodyData = {
//         videoId: comment.videoId,
//         commentId: comment.id,
//         like: comment.liked ? 2 : 1,
//       };

//       const response = await apiPatch("/comment", bodyData);

//       if (response.status === 200) {
//         const updatedComments = comments.map((c) =>
//           c.id === comment.id
//             ? {
//                 ...c,
//                 liked: !comment.liked,
//                 like: comment.liked ? c.like - 1 : c.like + 1,
//               }
//             : c
//         );
//         setComments(updatedComments);
//       } else {
//         console.error("Failed to like/unlike the comment:", response.status);
//       }
//     } catch (error) {
//       console.error("Error toggling like on comment:", error);
//     }
//   };

//   const handleDownloadClick = async () => {
//     try {
//       const bodyData = {
//         videoUserId: post.userId,
//         videoId: post.id,
//         downloads: 1,
//       };

//       const response = await apiPost("/updateVideo", bodyData);

//       if (response.status === 201) {
//         setDownloadCount((prevCount) => prevCount + 1);
//         triggerDownload(post.iosUrl);
//       } else {
//         console.error("Failed to update download count:", response.status);
//       }
//     } catch (error) {
//       console.error("Error downloading video:", error);
//     }
//   };

//   const triggerDownload = (url) => {
//     const link = document.createElement("a");
//     link.href = url;
//     link.setAttribute("download", "video.mp4");
//     document.body.appendChild(link);
//     link.click();
//     link.remove();
//   };

//   const handleShareClick = async () => {
//     try {
//       const bodyData = {
//         videoUserId: post.userId,
//         videoId: post.id,
//         share: 1,
//       };

//       const response = await apiPost("/updateVideo", bodyData);

//       if (response.status === 201) {
//         setShareCount((prevCount) => prevCount + 1);
//       } else {
//         console.error("Failed to update share count:", response.status);
//       }
//     } catch (error) {
//       console.error("Error sharing video:", error);
//     }
//   };

//   const handleEmojiClick = (emojiData) => {
//     setNewComment((prevComment) => prevComment + emojiData.emoji);
//     setShowEmojiPicker(false);
//   };

//   const handleCommentIconClick = () => {
//     inputRef.current.focus();
//   };

//   // API Helper Functions
//   const apiGet = async (endpoint, config = {}) => {
//     const token = localStorage.getItem("token");
//     try {
//       return await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}${endpoint}`, {
//         ...config,
//         headers: {
//           ...config.headers,
//           Authorization: `Bearer ${token}`,
//         },
//       });
//     } catch (error) {
//       console.error("API GET Error:", error);
//       throw error;
//     }
//   };

//   const apiPost = async (endpoint, data, config = {}) => {
//     const token = localStorage.getItem("token");
//     try {
//       return await axios.post(`${process.env.REACT_APP_BASE_URL_VIDEO}${endpoint}`, data, {
//         ...config,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//           ...config.headers,
//         },
//       });
//     } catch (error) {
//       console.error("API POST Error:", error);
//       throw error;
//     }
//   };

//   const apiPatch = async (endpoint, data, config = {}) => {
//     const token = localStorage.getItem("token");
//     try {
//       return await axios.patch(`${process.env.REACT_APP_BASE_URL_VIDEO}${endpoint}`, data, {
//         ...config,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//           ...config.headers,
//         },
//       });
//     } catch (error) {
//       console.error("API PATCH Error:", error);
//       throw error;
//     }
//   };

//   return {
//     currentIndex,
//     setCurrentIndex,
//     isPlaying,
//     timelineWidth,
//     buttonOpacity,
//     comments,
//     newComment,
//     liked,
//     likeCount,
//     downloadCount,
//     shareCount,
//     showEmojiPicker,
//     videoLoading,
//     videoContainerRef,
//     modalRef,
//     emojiPickerRef,
//     inputRef,
//     setTimelineWidth,
//     handleVideoPlay,
//     handleNextPost,
//     handlePreviousPost,
//     handleCommentChange,
//     handleCommentSubmit,
//     handleLikeClick,
//     handleCommentLikeClick,
//     handleDownloadClick,
//     handleShareClick,
//     handleEmojiClick,
//     handleCommentIconClick,
//     setShowEmojiPicker,
//     setVideoLoading,
//   };
// };

// export default useVideoPost;






import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";

import { useParams } from 'react-router-dom';
import { encode, decode } from "utf8";

const useVideoPost = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [buttonOpacity, setButtonOpacity] = useState(0);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const videoContainerRef = useRef(null);
  const modalRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const inputRef = useRef(null);



  useEffect(() => {
    if (post) {
      initializePostData();
      fetchComments();
    }
  }, []);

  // Initialize post-related data
  const initializePostData = () => {
    setLikeCount(post.likes || 0);
    setDownloadCount(post.downloads || 0);
    setShareCount(post.shares || 0);
  };


  // Toggle play/pause state
  const handleVideoPlay = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying); // Toggle play/pause
    setButtonOpacity(1); // Show the play/pause icon when toggled
    setTimeout(() => setButtonOpacity(0), 500); // Fade out the icon after 500ms
  };



  const { id } = useParams(); 
  const [post, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/videos?videoId=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          });
        console.log(response.data.data.Items[0],'reels video section')
  
        setPosts(response.data.data.Items[0]);
      } catch (err) {
        setError('Error fetching video');
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, []);


  

  // Fetch comments for the current post
  const fetchComments = async () => {
    try {
      const response = await apiGet("/comment", {
        params: { videoId: post.id },
      });

      if (response.status === 200 && response.data?.data?.Items) {
        const decodedComments = response.data.data.Items.map((comment) => ({
          ...comment,
          comment: decode(comment.comment),
        }));
        setComments(decodedComments);
      } else {
        console.error("Failed to fetch comments:", response.status);
      }
    } catch (error) {
      console.error("Error fetching video comments:", error);
    }
  };

  // Handle comment submission
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const encodedComment = encode(newComment);
      const formData = {
        videoUserId: post?.userId,
        videoId: post?.id,
        comment: encodedComment,
      };

      const response = await apiPost("/comment", formData);

      if (response.status === 201) {
        setNewComment("");
        fetchComments(); // Refetch comments after posting
      } else {
        console.error("Failed to post comment:", response.status);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  // Handle comment liking
  const handleCommentLikeClick = async (comment) => {
    try {
      const bodyData = {
        videoId: comment.videoId,
        commentId: comment.id,
        like: comment.liked ? 2 : 1, // Toggle like status
      };

      const response = await apiPatch("/comment", bodyData);

      if (response.status === 200) {
        const updatedComments = comments.map((c) =>
          c.id === comment.id
            ? {
                ...c,
                liked: !comment.liked,
                like: comment.liked ? c.like - 1 : c.like + 1,
              }
            : c
        );
        setComments(updatedComments);
      } else {
        console.error("Failed to like/unlike the comment:", response.status);
      }
    } catch (error) {
      console.error("Error toggling like on comment:", error);
    }
  };

  // Handle video like
  const handleLikeClick = async () => {
    try {
      const bodyData = {
        videoUserId: post.userId,
        videoId: post.id,
        like: liked ? 0 : 1,
      };

      const response = await apiPost("/updateVideo", bodyData);

      if (response.status === 201) {
        setLiked(!liked);
        setLikeCount((prevCount) => (liked ? prevCount - 1 : prevCount + 1));
      } else {
        console.error("Failed to like/unlike the video:", response.status);
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  // Handle video download
  const handleDownloadClick = async () => {
    try {
      const bodyData = {
        videoUserId: post.userId,
        videoId: post.id,
        downloads: 1,
      };

      const response = await apiPost("/updateVideo", bodyData);

      if (response.status === 201) {
        setDownloadCount((prevCount) => prevCount + 1);
        triggerDownload(post.iosUrl);
      } else {
        console.error("Failed to update download count:", response.status);
      }
    } catch (error) {
      console.error("Error downloading video:", error);
    }
  };

  // Download video helper
  const triggerDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "video.mp4");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // Handle video share
  const handleShareClick = async () => {
    try {
      const bodyData = {
        videoUserId: post.userId,
        videoId: post.id,
        share: 1,
      };

      const response = await apiPost("/updateVideo", bodyData);

      if (response.status === 201) {
        setShareCount((prevCount) => prevCount + 1);
      } else {
        console.error("Failed to update share count:", response.status);
      }
    } catch (error) {
      console.error("Error sharing video:", error);
    }
  };

  // Handle emoji picker selection
  const handleEmojiClick = (emojiData) => {
    setNewComment((prevComment) => prevComment + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  // Handle comment icon click to focus input
  const handleCommentIconClick = () => {
    inputRef.current.focus();
  };

  // Handle clicks outside modal to close emoji picker
  const handleClickOutside = useCallback((event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      (!emojiPickerRef.current || !emojiPickerRef.current.contains(event.target))
    ) {
      setShowEmojiPicker(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // API Helper Functions
  const apiGet = async (endpoint, config = {}) => {
    try {
      const token = localStorage.getItem("token");
      return await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}${endpoint}`, {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("API GET Error:", error);
      throw error;
    }
  };

  const apiPost = async (endpoint, data, config = {}) => {
    try {
      const token = localStorage.getItem("token");
      return await axios.post(`${process.env.REACT_APP_BASE_URL_VIDEO}${endpoint}`, data, {
        ...config,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          ...config.headers,
        },
      });
    } catch (error) {
      console.error("API POST Error:", error);
      throw error;
    }
  };

  const apiPatch = async (endpoint, data, config = {}) => {
    try {
      const token = localStorage.getItem("token");
      return await axios.patch(`${process.env.REACT_APP_BASE_URL_VIDEO}${endpoint}`, data, {
        ...config,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          ...config.headers,
        },
      });
    } catch (error) {
      console.error("API PATCH Error:", error);
      throw error;
    }
  };

  return {
    loading,
    error,
    isPlaying,
    timelineWidth,
    buttonOpacity,
    comments,
    newComment,
    liked,
    likeCount,
    downloadCount,
    shareCount,
    showEmojiPicker,
    videoLoading,
    videoContainerRef,
    modalRef,
    emojiPickerRef,
    inputRef,
    setTimelineWidth,
    handleVideoPlay,
    post,
    handleCommentChange,
    handleCommentSubmit,
    handleLikeClick,
    handleCommentLikeClick,
    handleDownloadClick,
    handleShareClick,
    handleEmojiClick,
    handleCommentIconClick,
    setShowEmojiPicker,
    setVideoLoading,
  };
};

export default useVideoPost;
