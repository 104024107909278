import React from 'react';
import MobileNav from './MobileNav';
import LargeNav from './LargeNav';

const Home = () => {
  return (
    <>
      {/* Sidebar section for large screens */}
      <div className="hidden md:block fixed top-0 left-0 z-40">
        <LargeNav />
      </div>
      
      {/* Bottom Navbar for small screens */}
      <div className="fixed bottom-0 left-0 w-full md:hidden z-50">
        <MobileNav />
      </div>
    </>
  );
};

export default Home;




