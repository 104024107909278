// slices/searchSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for searching users
export const searchUsers = createAsyncThunk('search/searchUsers', async (query, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_GENERAL}/discovery`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { search: query },
    });
    if (response.data?.data?.users) {
      return response.data.data.users;
    }
    return rejectWithValue('No users found.');
  } catch (error) {
    return rejectWithValue('Error performing search. Please try again.');
  }
});

// Async thunk for following/unfollowing users
export const followOrUnfollowUser = createAsyncThunk(
  'search/followOrUnfollowUser',
  async ({ userId, isFollowing }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${isFollowing ? 'unfollow' : 'follow'}`;
      const method = isFollowing ? 'delete' : 'post';

      const response = await axios({
        method,
        url: apiUrl,
        data: { userId },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200 || response.status === 201) {
        return { userId, isFollowing: !isFollowing };
      }
      return rejectWithValue('Unable to follow/unfollow. Please try again.');
    } catch (error) {
      return rejectWithValue('Error performing follow/unfollow action.');
    }
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    users: [],
    loading: false,
    error: null,
    loadingFollow: {}, // Separate loading state for follow/unfollow actions
  },
  reducers: {
    clearSearch: (state) => {
      state.users = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle searchUsers async thunk
      .addCase(searchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.users = [];
      })
      // Handle followOrUnfollowUser async thunk
      .addCase(followOrUnfollowUser.pending, (state, action) => {
        state.loadingFollow[action.meta.arg.userId] = true;
      })
      .addCase(followOrUnfollowUser.fulfilled, (state, action) => {
        state.loadingFollow[action.payload.userId] = false;
        state.users = state.users.map((user) =>
          user.id === action.payload.userId ? { ...user, isFollowing: action.payload.isFollowing } : user
        );
      })
      .addCase(followOrUnfollowUser.rejected, (state, action) => {
        state.loadingFollow[action.meta.arg.userId] = false;
        state.error = action.payload;
      });
  },
});

export const { clearSearch } = searchSlice.actions;

export default searchSlice.reducer;
