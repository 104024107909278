import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaCompass, FaHeart, FaUserCircle, FaSearch } from 'react-icons/fa';

const MobileNav = () => {
  const navLinks = [
    { to: '/reels', icon: <FaHome size={20} /> },
    { to: '/search', icon: <FaSearch size={20} /> },
    { to: '/explore', icon: <FaCompass size={20} /> },
    { to: '/notification', icon: <FaHeart size={20} /> },
    { to: '/profile', icon: <FaUserCircle size={20} /> },
  ];

  return (
    <nav className="flex justify-around bg-black p-2 text-white fixed bottom-0 left-0 shadow-lg w-full md:hidden">
      {navLinks.map(({ to, icon }) => (
        <NavLink 
          key={to} 
          to={to} 
          className={({ isActive }) =>
            `p-2 ${isActive ? 'text-[#56b5f4]' : 'text-white'}`
          }
        >
          {icon}
        </NavLink>
      ))}
    </nav>
  );
};

export default MobileNav;
